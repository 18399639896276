<template>
    <LottieAnimation :animation-data="lottie" :auto-play="true" :loop="true" :speed="1" ref="anim" />
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import lottie from "../../assets/lottie/wizard-loader.json";

export default {
    components: {
        LottieAnimation,
    },
    data() {
        return {
            anim: null,
            lottie,
        };
    },
};
</script>
